<script>

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

/**
 * Dashboard Component
 */
export default {
    page: {
    title: "Informations",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Informations",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Informations",
          active: true,
        },
        {
          text: "Cartes",
          active: true,
        },
      ],
      errors: [],
      informations: [],
      loading: false,
      status: 201,
      STORAGE_URL: Helper.STORAGE_URL,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('informations/card'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.informations = data.data
      })
    },
  },
  mounted() {
    this.loadList()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des Cartes</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>Cartes</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Forêt</th>
                    <th>Titre</th>
                    <th>Fichier</th>
                    <th>IF Status</th>
                    <th>Date d'approbation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(information, i) in informations" :key="information.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>
                      <router-link :to="'/ctaf/forests/'+information.forest.id">{{ information.forest.name }}</router-link>
                    </td>
                    <td>{{ information.title }}</td>
                    <td>
                      <a :href="STORAGE_URL+information.file" v-if="information.file !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                          <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                          Télécharger le Fichier
                      </a>
                    </td>
                    <td>
                      {{ (information.status == 1) ? "Approuvé" : ""  }}
                      {{ information.status == 0 ? "En étude" : "" }}
                      {{ information.status == -1 ? "Réjétée" : "" }}
                    </td>
                    <td>{{ information.date_approved }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
